<template>
    <v-app-bar
        app
        color="white"
        v-if="isLoggedIn"
        height="110"
        flat
    >
        <div class="toolbar-logo mr-auto mr-md-15">
            <router-link :to="{name: 'Home' }">
                <v-img
                    contain
                    height="67"
                    src="../../assets/images/fancom-logo.svg"
                ></v-img>
            </router-link>
        </div>

        <v-autocomplete
            ref="searchInput"
            v-model="result"
            class="toolbar-search mr-5"
            :items="searchResults"
            :loading="isSearching"
            :search-input.sync="query"
            item-text="label"
            :item-value="getItemValue"
            @change="goToSearchResult"
            no-filter
            append-icon="fal fa-search notransform"
            :hide-no-data="isSearching || !query"
            no-data-text="No results..."
            return-object
            hide-details
            label="Search..."
            solo
            v-if="!isMobile && isLoggedIn"
            v-on:keyup.enter="goToSearchPage"
        >
            <template v-slot:item="{ item }">
                <v-list-item-content>
                    <v-list-item-title>
                        {{item.label}}
                        <v-chip
                            v-if="item.archived"
                            class="ml-1"
                            color="red lighten-2"
                            x-small
                        >Discontinued</v-chip>
                        <v-chip
                            v-else-if="item.expiring"
                            class="ml-1"
                            color="warning"
                            x-small
                        >To be expired</v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.group" v-html="item.group"></v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

        <v-menu
            bottom
            right
            transition="scale-transition"
            origin="top right"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="header-user"
                    v-on="on"
                    v-if="user && !isMobile && isLoggedIn"
                    v-bind="attrs"
                >
                    <v-avatar left v-if="user && user.imageUrl" class="mr-2">
                        <v-img
                            :src="user.imageUrl"
                            :alt="user.username"
                        ></v-img>color-
                    </v-avatar>
                    <v-avatar tile size="36" color="accent" left v-else class="mr-2">
                        <v-icon small class="white--text">fal fa-user</v-icon>
                    </v-avatar>
                    {{ user.name }}
                    <v-icon color="accent" small class="ml-2">fal fa-angle-down</v-icon>
                </div>
            </template>
            <v-card width="300">
                <v-list v-if="user">
                    <v-list-item>
                        <v-list-item-avatar tile size="36" color="accent">
                            <v-img :src="user.imageUrl" v-if="user.imageUrl"></v-img>
                            <v-avatar v-else>
                                <span class="white--text headline">{{ user.name.charAt(0) }}</span>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ user.username }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                icon
                                @click="menu = false"
                            >
                                <v-icon small>fal fa-times</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-list>
                    <v-list-item @click="onClickPasswordChangeButton">
                        <v-list-item-action>
                            <v-icon small>fal fa-key</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Change password</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'UserOverview'}" v-if="isKeyUser">
                        <v-list-item-action>
                            <v-icon small>fal fa-users</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Manage users</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="onClickLogoutButton">
                        <v-list-item-action>
                            <v-icon small>fal fa-sign-out</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>

        <v-icon @click="$router.push({name: 'Search'})" v-if="isMobile && isLoggedIn" class="mr-3">fal fa-search</v-icon>
        <v-app-bar-nav-icon @click="$store.dispatch('toggleNavigationDrawer')" v-if="isMobile && isLoggedIn"></v-app-bar-nav-icon>

        <template v-slot:extension v-if="!isMobile && isLoggedIn">
            <v-container>
                <v-tabs
                    fixed-tabs
                    hide-slider
                >
                    <v-tab
                        v-for="item in items"
                        :key="item.title"
                        :to="{name: item.route}"
                        :exact="item.exact"
                        :id="'tab-' + item.title"
                    >
                        <v-menu
                            transition="slide-y-transition"
                            bottom
                            offset-y
                            open-on-hover
                            v-if="item.children"
                            :attach="'#tab-' + item.title"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ item.title }}
                            </span>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="childItem in item.children"
                              :key="childItem.title"
                              :to="{ name: childItem.route.name, params: childItem.route.params }"
                            >
                              <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <span v-else>{{ item.title }}</span>
                    </v-tab>
                </v-tabs>
            </v-container>
        </template>

    </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';
import routeSearcher from "../../router/routeSearcher";
import apiClient from "@/api/apiClient";

export default {
    name: "Header",
    data: () => ({
        isMobile: false,
        isSearching: false,
        query: '',
        searchResults: [],
        isSearchQueued: false,
        result: null,
    }),
    computed: {
        isKeyUser () {
            return this.$store.getters.isKeyUser
        },
        ...mapState({
            items: state => state.app.navigationItems,
            isLoggedIn: state => state.auth.isLoggedIn,
            user: state => state.auth.user
        })
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, {passive: true})
    },

    mounted() {
        this.onResize()

        window.addEventListener('resize', this.onResize, {passive: true})
    },

    watch: {
        query() {
            this.search();
        },
        isSearching() {
            if (!this.isSearching && this.isSearchQueued) {
                this.search();
            }
        },
    },

    methods: {
        onClickPasswordChangeButton() {
            this.$router.push({name:'ChangePassword'});
        },
        onClickLogoutButton() {
            this.$emit('show-logout-dialog', true)
        },
        onResize() {
            // Because Vuetify updates the innerWidth of the screen, we use the real screen width as a breakpoint.
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            this.isMobile = width < 665
        },
        getItemValue(data) {
            return JSON.stringify(data);
        },
        search() {
            // Handle queueing
            if (this.isSearching) {
                this.isSearchQueued = true;
                return;
            }
            this.isSearchQueued = false;
            this.searchResults = [];

            // Ignore if empty string
            if (this.query === null || this.query.trim() === '') {
                return;
            }

            this.isSearching = true;

            apiClient.get('/search', { params: {
                    query: this.query,
                }}).then(response => {
                if (response.status === 200) {
                    this.searchResults = response.data;
                } else {
                    console.error(response.data.error);
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
                }
            }).catch(() => {
                return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Ongeldige zoekopdracht'});
            }).finally(() => {
                this.isSearching = false;
            });
        },
        goToSearchResult() {
            routeSearcher.goToSearchResult(this.$router, this.result);
            this.result = null;
            this.searchResults = [];
        },
        goToSearchPage() {
            if (this.$route.name === 'Search') {
                this.$router.replace({name: 'Search', params: {query: this.query}});
            } else {
                this.$router.push({name: 'Search', params: {query: this.query}});
            }
            this.$refs.searchInput.blur();
        },
    },
}
</script>

<style scoped>

</style>
