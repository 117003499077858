import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import auth from './modules/auth'
import catalog from './modules/catalog'
import team from './modules/team'
import news from './modules/news'
import page from './modules/page'
import general from './modules/general'
import users from './modules/users'
import marketing from './modules/marketing'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'
let plugins = []
if (debug) {
    plugins.push(createLogger)
}
plugins.push(createPersistedState({
    key: process.env.VUE_APP_VUEX_STORAGE_NAME,
    paths: [
        'auth.isLoggedIn',
        'auth.user',
    ]
}))


export default new Vuex.Store({
    modules: {
        app,
        auth,
        catalog,
        team,
        news,
        page,
        general,
        users,
        marketing
    },
    strict: debug,
    plugins: plugins
})
