import apiClient from "@/api/apiClient";

// initial state
const state = {
    categories: [
        { name: 'Corporate', slug: 'corporate' },
        { name: 'Promotion', slug: 'promotion'},
        { name: 'Policies & prices', slug: 'policies-prices'}
    ],
    pages: [],
    menuPages: [],
    items: []
}

// getters
const getters = {

}

// actions
const actions = {
    getMarketingMenuPages({commit, state}, payload) {
        let forceReload = payload && payload.forceReload;
        if (!forceReload && state.menuPages.length >= 1) {
            return state.menuPages;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/marketing/pages')
            .then(response => {
                commit('SET_MENU_PAGES', response.data);
                return response.data;
            })
    },
    getMarketingPage({commit, state}, { slug }) {
        let pageItem = state.pages.find(page => page.slug === slug);
        if (pageItem) {
            return pageItem;
        }

        // TODO: Ook in localstorage met expiration
        return apiClient.get('/marketing/page/'+slug)
            .then(response => {
                commit('ADD_VIEW_PAGE', response.data);
                return response.data;
            })
    },
    getMarketingItems({commit, state}, payload) {
        let forceReload = payload && payload.forceReload;
        if (!forceReload && state.items[payload.type] && state.items[payload.type].length >= 1) {
            return state.items[payload.type];
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/marketing/items/'+payload.type)
            .then(response => {
                commit('SET_ITEMS', { type: payload.type, items: response.data });
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_MENU_PAGES(state, menuPages) {
        state.menuPages = menuPages;
    },
    ADD_VIEW_PAGE(state, pageItem) {
        state.pages.push(pageItem);
    },
    SET_ITEMS(state, payload) {
        state.items[payload.type] = payload.items;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
