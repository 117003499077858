import apiClient from "@/api/apiClient";

// initial state
const state = {
    pageTitle: null,
    settings: null,
}

// getters
const getters = {

}

// actions
const actions = {
    setPageTitle({commit}, pageTitle) {
        commit('SET_PAGE_TITLE', pageTitle);
    },
    getSettings({commit, state}) {
        if (state.settings) {
            return state.settings;
        }

        // TODO: Ook in localstorage met expiration
        return apiClient.get('/settings')
            .then(response => {
                commit('SET_SETTINGS', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_PAGE_TITLE(state, pageTitle) {
        state.pageTitle = pageTitle;
    },
    SET_SETTINGS(state, settings) {
        state.settings = settings;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
