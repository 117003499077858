import apiClient from "@/api/apiClient";

// initial state
const state = {
    teamMembers: [],
}

// getters
const getters = {

}

// actions
const actions = {
    getTeamMembers({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.teamMembers.length >= 1) {
            return state.teamMembers;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/team')
            .then(response => {
                commit('SET_TEAM_MEMBER', response.data);
                return response.data;
            })
    },
    getTeamMember({state, dispatch}, { slug }) {

        let findBySlug = (teamMembers) => {
            return teamMembers.find(teamMember => teamMember.slug === slug);
        }

        if (state.teamMembers.length === 0) {
            return dispatch('getTeamMembers').then(teamMembers => {
                return findBySlug(teamMembers);
            });
        } else {
            return findBySlug(state.teamMembers);
        }
    },
}

// mutations
const mutations = {
    SET_TEAM_MEMBER(state, teamMembers) {
        state.teamMembers = teamMembers;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
