import apiClient from "@/api/apiClient";

// initial state
const state = {
    users: [],
}

// getters
const getters = {
    activeUserCount: state => {
        return state.users.filter(u => u.active).length
    }
}

// actions
const actions = {
    getUsers({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.users.length >= 1) {
            return state.users;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/users')
            .then(response => {
                commit('SET_USERS', response.data);
                return response.data;
            })
    },
    getUserDetail({state, dispatch}, { id }) {

        let findById = (users) => {
            return users.find(user => user.id === id);
        }

        if (state.users.length === 0) {
            return dispatch('getUsers').then(users => {
                return findById(users);
            });
        } else {
            return findById(state.users);
        }
    },
    createUser({commit, rootGetters}, { user }) {
        let formData = new FormData();
        formData.append('company', user.company);
        formData.append('country', user.country);
        formData.append('business_partner_no', user.businessPartnerNo);
        formData.append('first_name', user.firstName);
        formData.append('last_name', user.lastName);
        formData.append('username', user.email);
        if (rootGetters.canUserViewPrices) {
            formData.append('show_prices', user.showPrices);
        }
        return apiClient.post('/users/create', formData)
            .then(response => {
                commit('SET_USERS', []); // clear local storage of users
                return response.data;
            })
    },
    updateUser({commit, rootGetters}, { user, id }) {
        let formData = new FormData();
        formData.append('username', user.username);
        formData.append('company', user.company);
        formData.append('country', user.country);
        formData.append('business_partner_no', user.businessPartnerNo);
        formData.append('first_name', user.firstName);
        formData.append('last_name', user.lastName);
        formData.append('active', user.active);
        if (rootGetters.canUserViewPrices) {
            formData.append('show_prices', user.showPrices);
        }
        return apiClient.post('/users/edit/'+id, formData)
            .then(response => {
                commit('SET_USERS', []); // clear local storage of users
                return response.data;
            })
    },
    deleteUser({commit}, { id }) {
        return apiClient.post('/users/delete/'+id)
            .then(response => {
                commit('SET_USERS', []); // clear local storage of users
                return response.data;
            })
    },

}

// mutations
const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
