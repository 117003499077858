import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import './plugins/vue-luxon';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-pro/css/all.css'

Vue.config.productionTip = false

console.log('%cFancom%c FAY Portal\r\n' +
    '%cVersion: %c' + process.env.VUE_APP_VERSION + '\r\n' +
    '%cAPI: %c' + process.env.VUE_APP_API_URL + '\r\n' +
    //'%cLanguage: %c' + i18n.locale + ' %c(' + navigator.languages + ') \r\n'+
    '%cDeveloped by: %cInternetbureau Forwart%c - %chttps://www.forwart.nl/',
    'color: #004237; font-weight: bold; font-size: 1.3em;',
    'color: #76ac1c; font-size: 1.3em;',
    'color: #888', 'color: #999;',
    'color: #888', 'color: #999;',
    //'color: #888', 'color: #28358D;', 'color: #ccc',
    'color: #888', 'color: #ec2e24; font-weight: bold', 'color: #333', 'color: #00f; text-decoration: underline;'
);

Vue.filter('format_price', function (number, supCents, prefix, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "," : decSep;
    thouSep = typeof thouSep === "undefined" ? "." : thouSep;
    prefix = typeof prefix === "undefined" ? "€ " : prefix;
    let sign = number < 0 ? "-" : "";
    let i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    let j = (j = i.length) > 3 ? j % 3 : 0;

    let cents = (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "")
    // if (supCents) {
    //     cents = '<sup>' + cents + '</sup>';
    // }

    if(cents == ',00') {
        cents = ',-';
    }

    return prefix + sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        cents;
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
