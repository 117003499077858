export default {
    /**
     * Navigates to the search result.
     */
    goToSearchResult(router, result) {
        if (result.type === 'portal_product_category') {
            router.push({name: 'CatalogProductOverview', params: { categorySlug: result.slug }});
        } else if (result.type === 'portal_product_series' || result.type === 'portal_product' || result.type === 'portal_spare_part' || result.type === 'portal_question') {
            router.push({name: 'CatalogProductSeries', params: { seriesSlug: result.slug }});
        } else if (result.type === 'portal_page') {
            router.push({name: 'InformationDetail', params: { slug: result.slug }});
        } else if (result.type === 'portal_news') {
            router.push({name: 'NewsItem', params: {slug: result.slug}});
        } 
        else if (result.type === 'portal_marketing_page') {
            router.push({name: 'MarketingPageDetail', params: { slug: result.slug }});
        }
        else if (result.type === 'portal_marketing_logo') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'logos-fancom' }});
        }
        else if (result.type === 'portal_marketing_press_release') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'press-releases' }});
        }
        else if (result.type === 'portal_marketing_sticker') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'stickers' }});
        }
        else if (result.type === 'portal_marketing_corporate_presentation') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'corporate-presentations' }});
        }
        else if (result.type === 'portal_marketing_gated_content') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'overview-of-gated-content' }});
        }
        else if (result.type === 'portal_marketing_brochure') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'brochures' }});
        }
        else if (result.type === 'portal_marketing_event') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'fancom-events' }});
        }
        else if (result.type === 'portal_marketing_promotional_product') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'promotional-products' }});
        }
        else if (result.type === 'portal_marketing_product_display') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'product-displays' }});
        }
        else if (result.type === 'portal_marketing_animation_video') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'animations-videos' }});
        }
        else if (result.type === 'portal_marketing_banner_visual') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'high-resolution-banner-visuals' }});
        }
        else if (result.type === 'portal_marketing_ad') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'ads' }});
        }
        else if (result.type === 'portal_marketing_house') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'houses-in-3d' }});
        }
        else if (result.type === 'portal_marketing_product_photo') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'product-photos' }});
        }
        else if (result.type === 'portal_marketing_product_presentation') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'product-presentations' }});
        }
        else if (result.type === 'portal_marketing_pricelist') {
            router.push({name: 'MarketingPageDetail', params: { slug: 'pricelist' }});
        }
        else if (result.type === 'portal_product_series_document') {
            window.open(result.url);
        }
    },
};
