import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    }, {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {requiresAuth: true}
    }, {
        path: '/catalog',
        name: 'Catalog',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/Catalog.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/ForgotPassword.vue'),
        meta: {requiresAuth: false}
    }, {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/ResetPassword.vue'),
        meta: {requiresAuth: false}
    }, {
        path: '/change-password/:token',
        name: 'ChangePassword',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/ChangePassword.vue'),
        meta: {requiresAuth: false}
    }, {
        path: '/catalog/product-series/:seriesSlug',
        name: 'CatalogProductSeries',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/CatalogProductSeries.vue'),
        beforeEnter: (to, from, next) => {
            if (from.params.sectorSlug) {
                to.meta.sectorSlug = from.params.sectorSlug;
            }
            if (from.params.categorySlug) {
                to.meta.sectorSlug = from.params.sectorSlug;
                to.meta.categorySlug = from.params.categorySlug;
            }
            next();
        },
        meta: {requiresAuth: true}
    }, {
        path: '/catalog/sector/:sectorSlug',
        name: 'CatalogSector',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/CatalogSector.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/catalog/:categorySlug/:sectorSlug?',
        name: 'CatalogProductOverview',
        component: () => import(/* webpackChunkName: "catalog" */ '../views/CatalogProductOverview.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/news',
        name: 'NewsOverview',
        component: () => import(/* webpackChunkName: "news" */ '../views/NewsOverview.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/news/:slug',
        name: 'NewsItem',
        component: () => import(/* webpackChunkName: "news" */ '../views/NewsItem.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "team" */ '../views/Contact.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/marketing',
        name: 'Marketing',
        component: () => import(/* webpackChunkName: "marketing" */ '../views/Marketing.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/marketing/:slug+',
        name: 'MarketingPageDetail',
        delimiter: '/',
        repeat: true,
        component: () => import(/* webpackChunkName: "marketing" */ '../views/MarketingPageDetail.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/users',
        name: 'UserOverview',
        component: () => import(/* webpackChunkName: "users" */ '../views/UserOverview.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/users/create',
        name: 'UserCreate',
        component: () => import(/* webpackChunkName: "users" */ '../views/UserCreate.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/users/edit/:userId',
        name: 'UserEdit',
        component: () => import(/* webpackChunkName: "users" */ '../views/UserEdit.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/search/:query?',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        meta: {requiresAuth: true}
    }, {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {requiresAuth: true}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.state.auth.isLoggedIn) {
            store.dispatch('setLoginRedirectRoute', to);
            next({
                name: 'Login'
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
