import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#004136',
                secondary: '#4D4D4D',
                accent: '#43BE64',
                error: '#ec2e24',
                info: '#5bc0de',
                success: '#76AC1C',
                warning: '#f0ad4e'
            },
        },
    },
    icons: {
        iconfont: 'fa',
        values: {
            sort: 'fal fa-long-arrow-up ml-1',
            prev: 'fal fa-chevron-left',
            next: 'fal fa-chevron-right',
            dropdown: 'fal fa-chevron-down',
            expand: 'fal fa-chevron-down',
        },
    },
});
