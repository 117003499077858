<template>
    <v-app :class="{ 'login-view': this.$route.name == 'Login' || this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword' }" :style="{ 'visibility': (isLoggedIn || this.$route.name == 'Login' || this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword') ? 'visible' : 'hidden' }">
        <NavigationDrawer v-if="isMobile" @show-logout-dialog="onShowLogoutDialog"></NavigationDrawer>
        <Header @show-logout-dialog="onShowLogoutDialog"></Header>

        <v-alert type="error" prominent border-bottom class="text-center" v-if="isIE">
            You are using an outdated browser. Please <a href="http://browsehappy.com/" class="white--text">upgrade your browser</a> to improve your experience.
        </v-alert>

        <v-main>
            <router-view @openZoho="openZohoChat"></router-view>
        </v-main>
        <AppAlert v-if="isLoggedIn"></AppAlert>
        <Snackbar></Snackbar>
        <v-footer class="pa-0" v-if="this.$route.name != 'Login' && this.$route.name != 'ForgotPassword' && this.$route.name != 'ResetPassword'">
            <v-container class="footer-wrap">
                <v-row>
                    <v-col cols="12" md="4"
                           class="d-flex align-center justify-center"
                           :class="{ 'justify-start': $vuetify.breakpoint.smAndUp } "
                    >
                        <router-link :to="{name: 'Home' }" class="mb-md-7">
                            <img
                                src="/img/fancom-logo.svg"
                                height="67"
                            />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="5">
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="d-flex align-end justify-center justify-md-end mb-5">
                            <v-btn small elevation="0" color="primary" href="https://www.fancom.com" title="Website" class="mr-2" target="_blank">
                                <v-icon x-small left @click="$router.push({name: 'Search'})" v-if="isLoggedIn">fa fa-globe</v-icon>
                                website
                            </v-btn>
                            <v-btn small elevation="0" color="primary" href="https://f-quote.fancom.com/" title="F-Quote" target="_blank">
                                <v-icon x-small left @click="$router.push({name: 'Search'})" v-if="isLoggedIn">fa fa-file-invoice</v-icon>
                                F-Quote
                            </v-btn>
                        </div>

                        <div class="d-flex align-end justify-center justify-md-end follow-us">
                            <v-btn icon color="accent" class="ml-1" href="https://twitter.com/fancombv" title="Twitter" target="_blank">
                                <v-icon size="24px">fab fa-twitter-square</v-icon>
                            </v-btn>
                            <v-btn icon color="accent" class="ml-1" href="https://www.facebook.com/fancombv" title="Facebook" target="_blank">
                                <v-icon size="24px">fab fa-facebook-square</v-icon>
                            </v-btn>
                            <v-btn icon color="accent" class="ml-1" href="http://www.linkedin.com/company/fancom-bv" title="LinkedIn" target="_blank">
                                <v-icon size="24px">fab fa-linkedin</v-icon>
                            </v-btn>
                            <v-btn icon color="accent" class="ml-1" href="http://www.youtube.com/user/FancomBV" title="YouTube" target="_blank">
                                <v-icon size="24px">fab fa-youtube</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex align-end justify-center justify-md-end mt-2">v{{ appVersion }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        <v-dialog
            v-model="showLogoutDialog"
            persistent
            max-width="300"
        >
            <v-card class="offwhite-card">
                <v-card-title class="primary-title justify-center">
                    <h1 class="text-h4">Logout</h1>
                </v-card-title>
                <v-card-text class="pt-5 text-center">Are you sure you want to logout?</v-card-text>
                <v-card-actions class="pa-4 pt-1">
                    <v-btn
                        text
                        color="secondary"
                        @click="showLogoutDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="logout">
                        <v-icon
                            small
                            left
                        >
                            fal fa-ban
                        </v-icon>
                        Logout
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>

import NavigationDrawer from "@/components/layout/NavigationDrawer";
import Header from "@/components/layout/Header";
import Snackbar from "@/components/layout/Snackbar";
import AppAlert from "@/components/layout/AppAlert";
import {mapState} from "vuex";

export default {
    name: 'App',

    components: {
        Snackbar,
        Header,
        NavigationDrawer,
        AppAlert,
    },

    data: () => ({
        isMobile: false,
        showLogoutDialog: false,
        isZohoInjected: false,
        appVersion: process.env.VUE_APP_VERSION,
        recentNewsDialog: false,
    }),


    beforeDestroy() {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, {passive: true})
    },
    mounted() {
        this.onResize()
        this.toggleZoho()

        window.addEventListener('resize', this.onResize, {passive: true})
    },
    watch: {
        pageTitle() {
            document.title = this.pageTitle + ' - ' + process.env.VUE_APP_TITLE;
        },
        isLoggedIn() {
            this.toggleZoho()
        },
    },
    computed: {
        isHelpdeskOnline() {
            let now = new Date();
            let startTime = '08:00:00';
            let endTime = '17:00:00';

            let startDate = new Date(now.getTime());
            startDate.setHours(startTime.split(":")[0]);
            startDate.setMinutes(startTime.split(":")[1]);
            startDate.setSeconds(startTime.split(":")[2]);

            let endDate = new Date(now.getTime());
            endDate.setHours(endTime.split(":")[0]);
            endDate.setMinutes(endTime.split(":")[1]);
            endDate.setSeconds(endTime.split(":")[2]);

            return now.getDay() === 6 || now.getDay() === 0 ? false : startDate < now && endDate > now;
        },
        isIE() {
            // IE 10 and IE 11
            return /Trident\/|MSIE/.test(window.navigator.userAgent);
        },
        ...mapState({
            pageTitle: state => state.general.pageTitle,
            isLoggedIn: state => state.auth.isLoggedIn,
            settings: state => state.general.settings,
        })
    },

    methods: {
        onShowLogoutDialog () {
            this.showLogoutDialog = true
        },
        logout () {
            this.$store.dispatch('logout').then(() => {
                this.showLogoutDialog = false
                this.$router.push({ name: 'Login' })
            })
        },
        onResize() {
            // Because Vuetify updates the innerWidth of the screen, we use the real screen width as a breakpoint.
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            this.isMobile = width < 665
        },
        toggleZoho() {
            if (!this.isZohoInjected) {
                if (this.isLoggedIn) {

                    // let s = document.createElement('script');
                    // s.type = 'text/javascript'
                    // s.src = 'https://desk.zoho.eu/portal/api/web/inapp/132281000001774121?orgId=20086309165';
                    // s.defer = true;
                    // let t=document.getElementsByTagName("script")[0];
                    // t.parentNode.insertBefore(s,t);

                    let $zoho=$zoho || {};
                    $zoho.salesiq = $zoho.salesiq || {widgetcode: "740f78e7bbf7a4af46ec1adc5c4920e73e48ffc427541f0d40f853a94215eae7f00fc4855ae285f68697dfc25325c5e9", values:{},ready:function(){}};

                    // Set global reference for the script
                    window.$zoho = $zoho;

                    // inject script
                    let s= document.createElement("script");
                    s.type="text/javascript";
                    s.id="zsiqscript";
                    s.defer=true;
                    s.src="https://salesiq.zoho.eu/widget";

                    let t=document.getElementsByTagName("script")[0];
                    t.parentNode.insertBefore(s,t);

                    // Set flag so we will not reinject the user
                    this.isZohoInjected = true;

                    // // After the widget has been loaded, provide it with user info
                    $zoho.salesiq.ready = () => {
                        // Load FAY user from store
                        let fayUser = this.$store.state.auth.user;

                        // Set SalesIQ Visitor data
                        // See: https://www.zoho.com/salesiq/help/developer-section/js-api-visitor-name.html
                        $zoho.salesiq.visitor.name(fayUser.name)
                        $zoho.salesiq.visitor.email(fayUser.username)

                        // We also add some extra info:
                        $zoho.salesiq.visitor.info({
                            "fayCompanyName": fayUser.company,
                            "fayBusinessPartnerNo": fayUser.businessPartnerNo,
                            "fayUserCountry": fayUser.country
                        })
                    }
                }
                return;
            }
            const el = document.querySelector('body > div:last-child');
            if (!el) {
                return;
            }
            el.style.setProperty('display', this.isLoggedIn ? 'block' : 'none', 'important');
        },
    }
};
</script>

<style lang="scss">
@import "assets/scss/app"
</style>
