<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-row class="login-form elevation-12">
                <v-col sm="5" class="primary intro d-none d-sm-flex">
                    <h1>Welcome</h1>
                    <p class="px-2 mb-4">Question about logging in? Please contact your Fancom Area Sales Manager</p>
                    <v-btn outlined color="accent" small href="https://www.fancom.com/distributors">Find your Area Sales Manager</v-btn>
                </v-col>
                <v-col sm="7" class="pa-0">
                    <v-form ref="form" @submit.prevent="checkLogin">
                        <v-card class="elevation-0">
                            <v-card-text>
                                <p class="mb-0 py-2 text-center">
                                    <img
                                        src="/img/fancom-logo.svg"
                                        height="67"
                                    />
                                </p>
                                <v-alert type="error" :value="error">{{ error }}</v-alert>
                                <v-text-field
                                    type="text"
                                    prepend-icon="far fa-user"
                                    v-model="username"
                                    label="E-mail / Username"
                                    :rules="usernameRules"
                                    required
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    prepend-icon="far fa-key"
                                    v-model="password"
                                    label="Password"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>
                                <v-checkbox
                                    v-model="rememberMe"
                                    label="Remember me"
                                ></v-checkbox>
                            </v-card-text>
                            <v-card-actions class="flex-wrap pb-3 pr-3">
                                <v-btn large type="submit" color="primary" class="ml-2">
                                    <v-icon
                                        small
                                        left
                                    >
                                        fal fa-sign-in
                                    </v-icon>
                                    Login
                                </v-btn>
                                <div class="break"></div>
                                <v-btn plain color="secondary" class="px-0 ml-2 mt-3" small :to="{name: 'ForgotPassword'}">Forgot password</v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-form>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import store from "../store";

export default {
    name: "Login",
    data: () => ({
        username: '',
        usernameRules: [
            v => !!v || 'Required'
        ],
        password: '',
        passwordRules: [
            v => !!v || 'Required'
        ],
        rememberMe: false,
        error: null
    }),
    mounted() {
        if (this.$store.state.auth.isLoggedIn) {
            this.$router.push({name: "Home"})
        }
        this.$store.dispatch('setPageTitle', 'Login')
    },
    methods: {
        checkLogin() {
            // Check if form is valid
            if (this.$refs.form.validate()) {

                // Submit data to auth store
                this.$store.dispatch('login', {
                    username: this.username,
                    password: this.password,
                    _remember_me: this.rememberMe,
                }).then((success) => {
                    if (success) {
                        let loginRedirectRoute = this.$store.state.auth.loginRedirectRoute;
                        if (loginRedirectRoute) {
                            store.dispatch('setLoginRedirectRoute', null);
                            this.$router.push(loginRedirectRoute)
                        } else {
                            this.$router.push({name: "Home"})
                        }
                    } else {
                        return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Invalid credentials'});
                    }
                }).catch(() => {
                    return this.$store.dispatch('showSnackbar', {color: 'error', text: 'Invalid credentials'});
                })
            }
        }
    }


}
</script>

<style scoped>

</style>
