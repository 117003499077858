// initial state
const state = {
    drawer: false,
    snackbar: {
        visible: false,
        text: '',
        color: 'success',
        timeout: 3000
    },
    navigationItems: [
        { icon: 'fal fa-fw fa-home', title: 'Home', route: 'Home', exact: true },
        {
            icon: 'fal fa-fw fa-shopping-cart',
            title: 'Catalog',
            route: 'Catalog',
            children: [
                {
                    title: 'Pigs',
                    route: {
                        name: 'CatalogSector',
                        params: {
                            sectorSlug: 'pigs'
                        }
                    }
                },
                {
                    title: 'Poultry',
                    route: {
                        name: 'CatalogSector',
                        params: {
                            sectorSlug: 'poultry'
                        }
                    }
                },
                {
                    title: 'Mushrooms',
                    route: {
                        name: 'CatalogSector',
                        params: {
                            sectorSlug: 'non-animals'
                        }
                    }
                }
            ]
        },
        { icon: 'fal fa-fw fa-newspaper', title: 'News', route: 'NewsOverview' },
        { icon: 'fal fa-fw fa-users', title: 'Contact', route: 'Contact' },
        { icon: 'fal fa-fw fa-info-circle', title: 'Marketing', route: 'Marketing' },
    ]
}

// getters
const getters = {

}

// actions
const actions = {
    showSnackbar({commit}, { text, timeout, color }) {
        if (!timeout) timeout = 3000;
        if (!color) color = 'success';
        commit('SHOW_SNACKBAR', { text, timeout })
    },
    toggleNavigationDrawer( { commit, state }, value) {
        if (typeof value !== 'undefined') {
            commit('SET_DRAWER_STATE', value);
        } else {
            commit('SET_DRAWER_STATE', !state.drawer);
        }

    }
}

// mutations
const mutations = {
    SHOW_SNACKBAR(state, { text, timeout, color }) {
        state.snackbar.visible = true;
        state.snackbar.text = text;
        state.snackbar.timeout = timeout;
        state.snackbar.color = color;
    },
    HIDE_SNACKBAR(state) {
        state.snackbar.visible = false;
    },
    SET_DRAWER_STATE(state, isOpen) {
        state.drawer = isOpen
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
