<template>
    <v-navigation-drawer app v-model="drawer" v-if="isLoggedIn">
        <p class="text-center mb-2">
            <router-link :to="{name: 'Home' }">
                <img
                    src="/img/fancom-logo.svg"
                    height="50"
                />
            </router-link>
        </p>
        <v-list
            nav
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="{name: item.route}"
                :exact="item.exact"
                link
            >
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <div class="bottom mt-auto">
            <v-btn outlined color="accent" small @click="onClickPasswordChangeButton" class="mb-3">
                <v-icon
                    small
                    left
                >
                    fal fa-key
                </v-icon>
                Change password
            </v-btn>
            <v-btn color="primary" @click="onClickLogoutButton">
                <v-icon
                    small
                    left
                >
                    fal fa-sign-out
                </v-icon>
                Logout
            </v-btn>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "NavigationDrawer",
    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
    }),
    computed: {
        drawer: {
            get() {
                return this.$store.state.app.drawer
            },
            set(value) {
                this.$store.dispatch('toggleNavigationDrawer', value);
            }
        },
        ...mapState({
            items: state => state.app.navigationItems,
            isLoggedIn: state => state.auth.isLoggedIn
        })
    },
    methods: {
        onClickPasswordChangeButton() {
            this.$router.push({name:'ChangePassword'});
        },
        onClickLogoutButton() {
            this.$emit('show-logout-dialog', true)
        }
    }
}
</script>

<style scoped>

</style>
