<template>
    <v-list two-line>
        <v-list-item
            v-for="document in documents"
            :key="document.url"
            :href="document.url"
            target="_blank"
            class="px-0 align-center"
        >
            <v-list-item-icon class="pl-4 my-auto">
                <v-icon color="accent">{{mimeTypeToIcon(document.fileType)}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    <small class="d-block text--secondary mb-1">{{ document.updatedAt|luxon('dd-MM-yyyy') }}</small>
                    {{ document.name }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import mimetype2fa from "../../utils/mimeTypeToFontAwesome5";

export default {
    name: "LatestServiceInfo",
    mounted() {
        this.$store.dispatch('getLatestServiceInfoDocuments');
    },
    computed: {
        documents() {
            return this.$store.state.catalog.latestServiceInfoDocuments;
        },
    },
    methods: {
        mimeTypeToIcon(mimeType) {
            return mimetype2fa(mimeType, { prefix: 'far fa-' });
        },
    }
}
</script>

<style scoped>

</style>
