// initial state
import apiClient from "@/api/apiClient";

const state = {
    isLoggedIn: false,
    user: null,
    loginRedirectRoute: null, // Route the user tried to go to while not logged in, should be redirected after logging in.
}

// getters
const getters = {
    canUserViewPrices: state => {
        return state.user && state.user.roles.includes('ROLE_PORTAL_PRICES_USER');
    },
    isKeyUser: state => {
        return state.user && state.user.roles.includes('ROLE_PORTAL_KEY_USER');
    }
}

// actions
const actions = {
    login({commit}, { username, password, _remember_me }) {
        commit('SET_AUTH_STATE', { isLoggedIn: false})
        return apiClient.post('/login', JSON.stringify({ username, password, _remember_me }))
            .then(response => {
                if (response.status === 200) {
                    commit('SET_AUTH_STATE', {isLoggedIn: true, user: response.data })
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    forgotPassword(_, { username }) {
        let formData = new FormData();
        formData.append('username', username);
        return apiClient.post('/forgot-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    resetPassword(_, { token, password }) {
        let formData = new FormData();
        formData.append('token', token);
        formData.append('password', password);
        return apiClient.post('/reset-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    updatePassword(_, { password, old_password }) {
        let formData = new FormData();
        formData.append('password', password);
        formData.append('old_password', old_password);
        return apiClient.post('/update-password', formData)
            .then(response => {
                if (response.status === 200) {
                    return true;
                } else {
                    console.error(response.data.error);
                    return false;
                }
            })
    },
    logout({commit}) {
        // TODO: Ook cookie verwijderen
        commit('CLEAR_AUTH_STATE');
    },
    setLoginRedirectRoute({commit}, route) {
        commit('SET_LOGIN_REDIRECT_ROUTE', route);
    }
}

// mutations
const mutations = {
    SET_AUTH_STATE(state, { isLoggedIn, user }) {
        state.isLoggedIn = isLoggedIn;
        state.user = user;
    },
    SET_LOGIN_REDIRECT_ROUTE(state, route) {
        state.loginRedirectRoute = route;
    },
    CLEAR_AUTH_STATE(state) {
        state.isLoggedIn = null;
        state.user = null;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
