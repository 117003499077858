<template>
    <v-container>
        <SectorList class="py-4"></SectorList>
        <v-row class="mb-3">
            <v-col cols="12" md="4">
                <v-card
                    elevation="0"
                >
                    <v-card-title
                        class="line mb-0"
                        :class="{ 'text-h5': $vuetify.breakpoint.lgAndUp, 'text-h6': $vuetify.breakpoint.mdAndDown }"
                    >
                        <v-icon
                            class="mr-2"
                            color="accent"
                        >
                            fal fa-newspaper
                        </v-icon>
                        Latest news
                    </v-card-title>
                    <LatestNews></LatestNews>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    elevation="0"
                >
                    <v-card-title
                        class="line mb-0"
                        :class="{ 'text-h5': $vuetify.breakpoint.lgAndUp, 'text-h6': $vuetify.breakpoint.mdAndDown }"
                    >
                        <v-icon
                            class="mr-2"
                            color="accent"
                        >
                            fal fa-tools
                        </v-icon>
                        Latest Service Information
                    </v-card-title>
                    <LatestServiceInfo></LatestServiceInfo>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    elevation="0"
                >
                    <v-card-title
                        class="line mb-0"
                        :class="{ 'text-h5': $vuetify.breakpoint.lgAndUp, 'text-h6': $vuetify.breakpoint.mdAndDown }"
                    >
                        <v-icon
                            class="mr-2"
                            color="accent"
                        >
                            fal fa-question
                        </v-icon>
                        Most recent FAQ's
                    </v-card-title>
                    <FaqList :questions="latestFaqs" class="mt-3"></FaqList>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import SectorList from "@/components/SectorList";
import LatestNews from "@/components/news/LatestNews";
import FaqList from "@/components/catalog/FaqList";
import LatestServiceInfo from "@/components/catalog/LatestServiceInfo";

export default {
    name: 'Home',
    mounted() {
        this.$store.dispatch('getLatestFaqs')
        this.$store.dispatch('setPageTitle', 'Home')
    },
    components: {
        SectorList,
        LatestNews,
        FaqList,
        LatestServiceInfo,
    },
    computed: {
        latestFaqs() {
            return this.$store.state.catalog.latestFaqs.slice(0, 4);
        }
    }

}
</script>
