<template>
    <v-list two-line>
        <v-list-item v-for="newsItem in newsItems" :key="newsItem.id" :to="{name:'NewsItem', params: { slug: newsItem.slug }}" class="px-0">
            <v-list-item-avatar tile size="60" v-if="newsItem.image">
                <v-img
                    :src="newsItem.image.url"
                    max-width="100%"
                    :alt="newsItem.title"
                    class="d-md-flex"
                ></v-img>
            </v-list-item-avatar>
            <v-list-item-avatar tile size="60" v-else>
                <v-img src="https://via.placeholder.com/60"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <small class="d-block text--secondary mb-1">{{ newsItem.date|luxon('dd-MM-yyyy') }}</small>
                    {{ newsItem.title }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon class="mr-1">
                    <v-icon color="accent">fal fa-chevron-right</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>

export default {
    name: "LatestNews",
    mounted() {
        this.$store.dispatch('getNewsItems');
    },
    computed: {
        newsItems() {
            // Get the 4 latest news items
            return this.$store.state.news.newsItems.slice(0, 4);
        },
    }
}
</script>

<style scoped>

</style>
