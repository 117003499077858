<template>
    <v-expansion-panels>
        <v-expansion-panel
            v-for="question in questions"
            :key="question.slug"
        >
            <v-expansion-panel-header>
                {{question.title}}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-html="question.answer">
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <!-- TODO: Alleen eerste drie tonen, met optie tot uitklappen van meer -->
</template>

<script>
export default {
    name: "FaqList",
    props: {
        questions: {
            type: Array
        }
    }
}
</script>

<style scoped>

</style>
