<template>
    <v-row class="mb-3">
        <v-col cols="12" md="4" v-for="sector in sectors" :key="sector.slug">
            <v-card
                :to="{name:'CatalogSector', params: { sectorSlug: sector.slug }}"
                block
                class="offwhite-card sector-card"
                elevation="0"
            >
                <v-img
                    :src="getSectorImage(sector)"
                    aspect-ratio="1"
                ></v-img>
                <v-card-title>
                    {{ sector.name }}
                </v-card-title>
            </v-card>
            <v-card
                block
                class="offwhite-card sector-category-card py-3"
                elevation="0"
                v-if="$vuetify.breakpoint.lgAndUp"
            >
                <div
                    v-for="category in getSectorCategories(sector)"
                    :key="category.slug"
                    class="sector-category"
                >
                    <router-link :to="{name:'CatalogProductOverview', params: { sectorSlug: sector.slug, categorySlug: category.slug }}">
                        <v-icon x-small color="accent" class="mr-3">fal fa-chevron-right</v-icon>
                        {{ category.name }}
                    </router-link>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "SectorList",
    computed: {
        ...mapState({
            sectors: state => state.catalog.sectors,
        })
    },
    data: () => ({
        categories: null,
    }),
    mounted () {
        this.$store.dispatch('getCategories').then(categories => {
            this.categories = categories;
        }).catch(() => {
            this.categories = [];
        })
    },
    methods: {
        getSectorImage (sector) {
            return '/img/' + sector.slug + '-v202201.jpg'
        },
        getSectorIcon (sector) {
            return '/img/' + sector.slug + '.svg'
        },
        getSectorCategories (sector) {
            if (!this.categories) {
                return [];
            }
            return this.categories.filter(c => c.sectors && c.sectors.includes(sector.slug));
        },
    }
}
</script>

<style scoped>

</style>
