import apiClient from "@/api/apiClient";

// initial state
const state = {
    sectors: [
        // TODO Luuk: Icons van website?
        { name: 'Pigs', slug: 'pigs' },
        { name: 'Poultry', slug: 'poultry'},
        { name: 'Mushrooms', slug: 'non-animals'}
    ],
    categories: [],
    productGroups: [],
    productSeries: [],
    latestFaqs: [],
    latestServiceInfoDocuments: [],
}

// getters
const getters = {

}

// actions
const actions = {
    getCategories({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.categories.length >= 1) {
            return state.categories;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/product/categories')
            .then(response => {
                commit('SET_CATEGORIES', response.data);
                return response.data;
            })
    },
    getCategory({state, dispatch}, { slug }) {

        let findById = (categories) => {
            return categories.find(category => category.slug === slug);
        }

        if (state.categories.length === 0) {
            return dispatch('getCategories').then(categories => {
                return findById(categories);
            });
        } else {
            return findById(state.categories);
        }
    },
    getProductGroups({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.productGroups.length >= 1) {
            return state.productGroups;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/product/groups')
            .then(response => {
                commit('SET_PRODUCT_GROUPS', response.data);
                return response.data;
            })
    },
    getProductGroup({state, dispatch}, { slug }) {

        let findById = (productGroups) => {
            return productGroups.find(productGroup => productGroup.slug === slug);
        }

        if (state.productGroups.length === 0) {
            return dispatch('getProductGroups').then(productGroups => {
                return findById(productGroups);
            });
        } else {
            return findById(state.productGroups);
        }
    },
    getLatestFaqs({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.latestFaqs.length >= 1) {
            return state.latestFaqs;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/latest-faqs')
            .then(response => {
                commit('SET_LATEST_FAQS', response.data);
                return response.data;
            })
    },
    getProductSeries({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.productSeries.length >= 1) {
            return state.productSeries;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/product/series')
            .then(response => {
                commit('SET_PRODUCT_SERIES', response.data);
                return response.data;
            })
    },
    getProductSeriesDetail(_, { slug }) {
        return apiClient.get('/product/series/'+slug)
            .then(response => {
                return response.data;
            })
    },
    getLatestServiceInfoDocuments({commit, state}, params) {
        let forceReload = params && params.forceReload;
        if (!forceReload && state.latestServiceInfoDocuments.length >= 1) {
            return state.latestServiceInfoDocuments;
        }
        // TODO: Ook in localstorage met expiration
        return apiClient.get('/document/service-info/latest')
            .then(response => {
                commit('SET_LATEST_SERVICE_INFO_DOCUMENTS', response.data);
                return response.data;
            })
    },
}

// mutations
const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    SET_PRODUCT_GROUPS(state, productGroups) {
        state.productGroups = productGroups;
    },
    SET_PRODUCT_SERIES(state, productSeries) {
        state.productSeries = productSeries;
    },
    SET_LATEST_FAQS(state, latestFaqs) {
        state.latestFaqs = latestFaqs;
    },
    SET_LATEST_SERVICE_INFO_DOCUMENTS(state, latestServiceInfoDocuments) {
        state.latestServiceInfoDocuments = latestServiceInfoDocuments;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
