<template>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="390"
    >
        <v-card v-if="settings">
            <v-card-title class="text-h5 primary white--text mb-5">
                {{ settings.alertTitle }}
            </v-card-title>
            <v-card-text style="white-space: pre-line;">{{ settings.alert }}</v-card-text>
            <v-card-text v-if="settings.alertLink"><b>Link:</b> <a :href="settings.alertLink" target="_blank">{{ settings.alertLink }}</a></v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="showDialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "AppAlert",
    data: () => ({
        showDialog: false,
    }),
    mounted() {
        this.$store.dispatch('getSettings');
    },
    watch: {
        settings() {
            this.checkForAlert();
        },
    },
    computed: {
        ...mapState({
            settings: state => state.general.settings,
        })
    },
    methods: {
        checkForAlert() {
            if (!this.settings || !this.settings.alert) {
                this.showDialog = false;
                return;
            }

            let appAlert = encodeURIComponent(this.settings.alert);
            let oldAlert = this.getCookie("appAlert");

            if (oldAlert === appAlert) {
                this.showDialog = false;
                return;
            }

            this.showDialog = true;
            this.setCookie("appAlert", appAlert, 365);
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
    }
}
</script>

<style scoped>

</style>
